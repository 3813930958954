import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

const NotFoundPage = ({data}) => (
  <Layout metadata={data.site.siteMetadata}>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`